import React from 'react';
import { Link } from 'react-router-dom';

export const topics = [
  {
    heading: 'About Carrot',
    qna: [
      { answer: 'Carrot is a digital lending platform that gives you access to a line of credit in Dollars, at low interest rates through the utilization of your digital assets as collateral.', question: 'What is Carrot?' },
      { answer: 'We currently do not provide customer support via phone or in person. However, you can reach us by sending an email to support@carrotcredit.com', question: 'Can I call a Carrot representative or visit the Carrot office?' },
      { answer: 'We can only extend an offer to a user who has a portfolio on any of our partner apps, so you must sign up through a Carrot partner.', question: 'I don\'t have an account with a Carrot partner but I want to sign up?' },
      { answer: 'We are present in Nigeria at the moment and we service users with digital assets. However, we hope to extend our offer to other African countries and the world at large', question: 'Carrot is present in which countries?' },
    ],
  },
  {
    heading: 'Sign up',
    qna: [
      {
        answer:
  <>
    You can find our user agreement &nbsp;
    <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/user-agreement">here</Link>
    .
  </>,
        question: 'I want to know your user agreement before I sign up?',
      },
      { answer: 'Log in to the website or go through our banner on the partner app. On your dashboard, click "Apply for Credit", select "Vendor". You will see the amount you are eligible to access, and you can then choose to take the entire amount or enter the exact value you require. Please keep in mind that you will not be able to access an amount greater than the amount you are eligible to borrow.', question: 'How do I apply for credit on Carrot?' },
      { answer: 'After successfully applying, the value requested goes into your Carrot wallet and can be accessed by selecting "Withdraw" on the dashboard. Select your withdrawal preference - Naira or Dollar account and the value will be received within the sitipulated timeline.', question: 'I just finished the application process, but I have no idea where the money went.' },
      { answer: 'On the sign in page on our website, click on "Forgot Password?", input your registered phone number or email and click on "Send OTP". A one time password will be sent. Kindly input the OTP and reset your password.', question: 'How do I reset my password?' },
      {
        answer:
  <>
    Here are some reasons why you may be experiencing issues while trying to add your card:
    <br />
    1. If you are trying to add an expired card.
    {' '}
    <br />
    2. If you have unsuccessfully attempted to add your card numerous times, you may encounter an error. When this happens, our payment processor&apos;s fraud engine may identify the efforts as fraudulent and return the error &quot;Unable to Process Transaction.&quot;
    {' '}
    <br />
    When this happens, you can try again 24 hours later; the restriction on your card(s) should have cleared by then.
    {' '}
    <br />
    3. If details such as cvv, card expiry date or card number are wrong. So you must ensure that your card details are correct.
    {' '}
    <br />
    4. If your card is not enabled for online transactions (Please contact your bank)
    {' '}
    <br />
    5. Insufficient funds. If the card does not have at least the authentication fee stipulated by our payment processor.
    {' '}
    <br />
    6. If you are attempting to add a non-Naira debit card, you will encounter an error message because our payment processors only accept Naira cards.
    {' '}
    <br />
    If none of the reasons stated above apply to you, kindly try again after a while or contact
    {' '}
    <a href="mailto:support@carrotcredit.com" style={{ color: '#FE5000', textDecoration: 'none' }}>support@carrotcredit.com</a>
    {' '}
    for assistance.
  </>,
        question: 'Why can’t I add my card?',
      },
      {
        answer:
  <>
    This is happening because you attempted to add your card numerous times without success, our payment processor’s fraud engine has temporarily stopped your transaction(s). Try again 24 hours after your previous try; the restriction on your card(s) should have cleared by then.
    {' '}
    <br />
    However, the account details you enter must match the name on your profile for withdrawal to be successful.
  </>,
        question: 'Why do I keep getting "Unable to Process Transaction" error message each time I try to add my card?',
      },
    ],
  },
  {
    heading: 'Eligibility Criteria',
    qna: [
      { answer: 'As soon as your credit application is confirmed as successful, you will receive feedback within 15 minutes.', question: 'How long does credit disbursement take?' },
      { answer: 'We do not require any documents to enable us to process a credit request. However, we expect you to fill all necessary fields during the application process.', question: 'Do I need to provide any documents to secure a line of credit?' },
      { answer: 'We offer a line of credit to individuals who are users of our partner investment platforms and have investments, such as stocks and other assets, that can be used as collateral. If you have investments on our partner platforms, an offer will be extended to you. If you have not received an offer yet, it will be provided to you soon, as offers are gradually extended to different customer segments at intervals.', question: 'How is my credit eligibility determined?' },
      { answer: 'The credit approved is determined by the value of your asset on your Carrot partner app. Typically, we offer 30% of your assets. However, the exact amount you are eligible to borrow will be displayed on your Carrot dashboard during application.', question: 'How much am I eligible to borrow?' },
      { answer: 'If the full amount you were eligible for was disbursed, you won’t be able to secure another credit until your ongoing credit is repaid partially or fully. However, if you initially opted for a partial credit amount, then, you can request for up to the approved limit. See your dashboard for details of the amount available for you to borrow.', question: 'I already have a credit line, but I’d like to request another.' },
      {
        answer:
  <>
    If you select a Naira account, you should receive it almost immediately or within 24 hours, depending on your bank.
    {' '}
    <br />
    However, if you select a dollar account, a wire transfer will be done, and receipt typically takes 1-3 business days.
  </>,
        question: 'I want my credit disbursed to a different account',
      },
    ],
  },
  {
    heading: 'Tenured Credit',
    qna: [
      { answer: 'Tenured credit provides a structured way to repay a credit. Unlike the regular credit option - revolving credit - with variable minimum payments, tenured credit lets you choose a fixed term (3 or 6 months) and divide your total credit amount equally into those monthly payments. This guarantees a set amount for principal repayment each month, alongside interest calculated on the remaining balance.', question: 'What is Tenured Credit?' },
      {
        answer:
  <>
    You have the choice to opt for a tenured credit line during your application process by following these steps:
    <ol>
      <li> Access your Carrot dashboard and click on &quot;apply for credit&quot;.</li>
      <li>This will direct you to the eligibility page. Click on &quot;Request an amount&quot;.</li>
      <li>Enter the desired credit amount or choose &quot;Max&quot; for the full amount you&apos;re eligible to apply for.</li>
      <li>Right beside &quot;Max&quot;, locate the option for &quot;Tenure&quot; and click on it. This will allow you to select your preferred tenure.</li>
      <li> After this, click &quot;Apply&quot;.</li>
      <li>Upon completing these steps, you will have submitted an application for a tenured credit line.</li>
    </ol>
  </>,
        question: 'How do I opt for tenured credit?',
      },
      { answer: 'Currently, we have 2 tenures available - 3 months and 6 months. You can opt for your preferred tenure during the application process.', question: 'What are the tenures available?' },
      {
        answer:
  <>
    You may also complete your application without selecting a tenure as this option during application
    is not compulsory. This will imply that you will be on revolving credit. Check out the other topics in
    the
    {' '}
    <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/faqs">FAQs</Link>
    {' '}
    to understand how revolving credit works.
  </>,
        question: 'What if I don\'t want a tenured credit line?',
      },
      { answer: 'The interest rates applicable during the first ever repayment and subsequent repayments on a revolving credit still apply to tenured credit (view the interest and repayment sections for more). When you choose a tenure (3 or 6 months), your total credit amount is divided equally into that number of monthly payments. This means you\'ll pay a consistent amount for the principal each month throughout your chosen tenure, while the interest may vary based on the principal to be paid each month. For instance, if you receive credit of $99 and select the 3 months tenure, it means that at the end of each month your minimum repayment will comprise $33 for the principal + the monthly interest. Summarily, with tenured credit, you get predictability for the principal repayments and each month\'s principal, making it easy to ascertain the amount due per month.', question: 'How will my repayments be calculated?' },
      {
        answer:
  <>
    If you are unable to fully offset the loan during the tenure you selected,
    your credit will roll over and become a regular revolving credit. When this
    happens all the terms that apply to credits without tenures will apply. When this
    occurs you will be expected to pay at least the minimum debt displayed on your dashboard each month.
    Kindly check out the other topics in the
    {' '}
    <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/faqs">FAQs</Link>
    {' '}
    to understand how revolving credit works.
  </>,
        question: 'What happens if I can\'t meet up with the tenure I opted for?',
      },
      {
        answer:
  <>
    Liquidation of your investment stocks can be triggered if repayment defaults occur
    3 months consecutively. A liquidation instruction will be sent to the partner and
    the recovered funds will be used to clear the pending balance. Read up about liquidations
    under that category of the
    {' '}
    <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/faqs">FAQs</Link>
    .
  </>,
        question: 'Will liquidation occur for tenured loans?',
      },
      { answer: 'Go to the "Recent activities" segment on your dashboard, under "Credit" , click on any of the credits you have applied for. This action will open up the details of the credit, allowing you confirm the exact tenure of a particular credit', question: 'How do I identify a tenured credit line on my dashboard?' },
      { answer: 'Firstly, a roll over from tenured credit to revolving credit will occur when at the end of a particular tenure you fail to completely pay off your total debt. This happens for instance after the 3rd month of a 3 months tenure credit or 6th month of a 6 months tenured credit. The credit will be converted into a revolving credit and will remain open until you pay off your total debt. However, if a default occurs 3 months consecutively during the tenure, rather than the tenured loan getting converted, your portfolio will be liquidated automatically.', question: 'What happens when tenured credit is rolled over?' },
      { answer: "It simply means that your credit which may previously have been restricted to a tenure of either 3 or 6 months will now be converted to a revolving credit. Which means that the credit will be open until you can successfully pay off your total debt. Once conversion occurs, you'll have a minimum monthly repayment amount displayed on your dashboard. You are expected to make this payment each month until your credit line is fully closed.", question: 'If my line of credit is rolled over what does this mean for me?' },
      { answer: 'If you miss a repayment during your credit line tenure, it will convert to a revolving credit with a minimum monthly payment of 6% of your remaining balance. This payment will cover both principal and interest, each at 3%. For example, If you miss the payment in the third month of a 3-month tenure and your rolled-over debt is $90, your first payment after the rollover will be 6% of $90, which is $5.40 ($2.70 for principal and $2.70 for interest). Future payments will be based on the remaining unpaid principal.', question: 'How will my repayments be calculated if my credit is rolled over?' },
      {
        answer:
  <>
    Unfortunately, you can&apos;t currently switch a revolving credit to a fixed tenure plan.
    However, you can accelerate your debt repayment by making payments exceeding the minimum amount due each month.
    To learn more about credit line repayments and interest rates, check out the &quot;credit line repayment&quot;
    and &quot;interest rate&quot;
    sections in our
    {' '}
    <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/faqs">FAQs</Link>
    . Alternatively, you can reach out to our support team at support@carrotcredit.com
    for further assistance.
  </>,
        question: 'Can I switch my previous revolving credit to a tenured credit?',
      },
    ],
  },
  // {
  //   heading: 'Leveraging Assets on Trove',
  //   qna: [
  //     { answer: 'To access a credit line, you simply have to select the particular stocks/Investments (e.g Salesforce, Snapchat etc) you want collateralized and proceed with a request. You will be transferred from the Trove app to Carrot, to enable you view the amount that you are eligible to borrow. Then you can proceed with apply for the desired amount you would like to access.', question: 'As a Trove user, how do I leverage my assets inorder to secure a credit line?' },
  //     { answer: 'Currently, users can exclusively secure a credit line by collateralizing their US portfolios. Should we decide to broaden this opportunity to users with Nigerian portfolios and fixed returns portfolios, we will make sure to inform all our users accordingly.', question: 'Can I access a credit line by leveraging my Nigerian stocks/portfolio?' },
  //     { answer: 'When your application success notification pops up, you may choose to click on "Go to Dashboard" or you may decide to access your dashboard by directly logging in via our website. Once on the dashboard, you will see that the amount you applied for will be in your current balance/wallet. That value can be accessed by clicking on "Withdraw from your wallet", after which you may proceed with the withdrawal to a valid naira or dollar account.', question: 'After I input the amount I am eligible to borrow, how do I access the money?' },
  //     { answer: 'Each investment selected as collateral must be worth at least $10 to be considered. However, it is important to note that our credit line is a minimum of $50. So you may need to collateralize stocks/investments worth a total of $170 or more, because you are only eligible to borrow 30% of your collateralized assets.', question: 'What should the minimum value of each stock/investment (e.g Google) be in order to be collaterizable?' },
  //     { answer: 'You can collateralize as many stocks as possible to secure a credit line. However, you must leverage at least 1 stock/investment in order to secure a credit line.', question: 'How many stocks/investments can I collaterize at once?' },
  //     { answer: 'Once an investment has been leverage to secure a credit line, it can no longer be collateralized until the debt secured as a result of the collateralization is paid off.', question: 'How many times can I collateralize a stock/investment (e.g Amazon)?' },
  //   ],
  // },
  {
    heading: 'Withdrawal',
    qna: [
      { answer: 'Click "Withdraw" on your dashboard, enter the amount you want to withdraw, or choose “max” to select the maximum amount that you are eligible to borrow. Then, choose your withdrawal preference—either a Naira or Dollar account—and you will receive the funds within the specified time.', question: 'How do I access the money in my wallet?' },
      { answer: 'No, it won’t. Credit is only considered active once it has been successfully withdrawn to a valid Naira or Dollar account. After the withdrawal is completed, the credit becomes active, and your dashboard will be updated accordingly.', question: 'If I apply for credit but don’t withdraw the funds immediately, will the balance in my wallet start accruing interest?' },
      { answer: 'We do not charge our users any fees during withdrawal, however, if you notice a fee being added, please note that it may be a processing fee from our 3rd party payment processors.', question: 'Is there a Withdrawal fee ?' },
      { answer: 'The credit line we extend to our users is for personal needs, for this reason the value withdrawn is deposited into a personal bank account to enable users settle their individual needs.', question: 'Can I withdraw directly to a partner app?' },
      { answer: 'If you select a Naira account, you should receive it almost immediately or within 24 hours, depending on your bank. However, if you select a dollar account, a wire transfer will be done, and receipt typically takes 1-3 business days.', question: 'After withdrawal, how long will it take before I receive the value in my bank account?' },
      {
        answer:
  <>
    During withdrawal you can provide alternative account details by selecting &quot;Add new account&quot; under the Naira or Dollar withdrawal options.
    {' '}
    <br />
    However, the account details you enter must match the name on your profile for your withdrawal to be successful. If there&apos;s a name mismatch, you will be required to send a valid form of identification. For more information, contact us via
    {' '}
    <a href="mailto:support@carrotcredit.com" style={{ color: '#FE5000', textDecoration: 'none' }}>support@carrotcredit.com</a>
  </>,
        question: 'I want to use a different account during withdrawal?',
      },
      { answer: 'Yes, you can. However, you must be aware that each withdrawal is considered an individual line of credit.', question: 'Can I make multiple withdrawals?' },
      { answer: 'The minimum value that can be withdrawn is $50. This means that for a user to be eligible for a credit line, 30% of their portfolio must be worth at least $50.', question: 'What is the minimum amount that can be withdrawn?' },
      { answer: 'The maximum amount a person can borrow is determined by their current portfolio worth, as all borrowers have access to a credit line of exactly 30% of their portfolio value.', question: 'What is the maximum amount that can be withdrawn?' },
      { answer: 'This error will be displayed if the card added to your account is about to expire within three months or below. To withdraw successfully, you must add a valid card that will expire 4 months or longer from the day it is linked to our account. After that, you may retry your withdrawal.', question: 'I am attempting a withdrawal but I keep getting an error about my card expiring?' },
    ],
  },
  {
    heading: 'Credit Line Repayment',
    qna: [
      { answer: 'During sign up, you will be required to fill in your card details for repayment and every month the card will be debited until repayment is completed. You can also change card by selecting "card" on the dashboard for monthly deductions. Also, if you would like to trigger your repayment when it is due, you can simply click on the “repay” on the dashboard and input the amount to be repaid.', question: 'How do I repay?' },
      { answer: 'Regardless of the day of the month on which your credit was obtained, the next due date for all users is the first day of the month e.g., January 1st, February 1st etc.', question: 'How do I know my next repayment date?' },
      { answer: 'We migrated the repayment of all users with active credits on November 1st 2021. For repayments that were over 1 month, the interest was 3% + a prorated sum (to take care of the additional days).', question: 'When did next repayment date change to the first day of the month?' },
      { answer: 'If the amount borrowed has been held for less than a month by the 1st of the upcoming month, your repayment date will be moved to the 1st of the following month. During repayment, we will take into account the 30 days, plus the remaining days leading up to the 1st. However, your next due date and the amount to be repaid can be confirmed via your dashboard.', question: 'With the repayment date being the first of every month, how does the first repayment work, especially if the duration of your credit line is less than 30 days?' },
      { answer: 'Early repayment increases your credit score and allows for quick credit approval. However, the amount you are eligible to receive will not increase based on early repayment.', question: 'Will the amount I am eligible to borrow increase if I repay early?' },
      { answer: 'The minimum repayment expected per month is 6%. This comprises a 3% interest rate and 3% repayment of the principal. This repayment plan will occur monthly until the credit\'s principal is fully repaid.', question: 'What is the minimum monthly repayment?' },
      { answer: 'To pay off your credit before your credit tenure elapses, click on the "repay" on the dashboard and insert the total credit amount displayed on your dashboard or click on "Max" to select the total amount due.', question: 'I want to repay my total debt at once?' },
      { answer: 'On the dashboard, under recent activities select the credit table and go to the exact credit you want serviced, select repay and input the amount you want repaid, then you proceed with the repayment.', question: 'How can I service or pay off a particular credit?' },
      { answer: 'The breakdown of your credit repayment is available on your Carrot dashboard. Log in to access the information.', question: 'Where do I see a breakdown of my credit repayment?' },
      { answer: 'Repayment can be done either in Naira or Dollars. However, if you intend to repay in Naira, you will be charged the Dollar equivalent of the amount to be repaid. Note that, the exchange rate during repayment may vary depending on the rate provided by our financial partners.', question: 'Must credit repayment be done in Dollars?' },
      { answer: 'If your repayment is not displayed on the dashboard, we advise that you confirm if you were successfully debited. If the debit is successful but the repayment does not reflect on the dashboard, kindly send an email to support@carrotcredit.com and the issue will be resolved.', question: 'Why is my repayment not reflecting on the dashboard?' },
      { answer: 'Your card information makes credit repayment easier, faster, and seamless because we automatically debit your card when your credit repayment is due. This method is secure because we have partnered with licenced payment processors to ensure that your card information is encrypted and inaccessible even to us.', question: 'Why is my card information required?' },
      { answer: 'We do not charge our users any fees during repayment. However, our 3rd party payment processors attach a fee for every Naira repayment made via their platform. Please note that this is not controlled by Carrot.', question: 'Is there a  repayment fee?' },
    ],
  },
  {
    heading: 'Redline',
    qna: [
      { answer: 'When a credit application is approved on Carrot, a portion of the user’s assets are locked on the partner app as collateral. This means that while a borrower can continue with their activities on the partner app, they will be unable to encroach on the locked funds. That amount that they will be unable to encroach on is known as the red line.', question: 'What does redline mean?' },
      { answer: 'The restriction placed on your account is as a result of your ongoing credit. This restriction will be lifted gradually until repayment is completed.', question: 'Why is there a hold on the assets in my account on my Carrot partner app?' },
      { answer: 'When a credit is secured on Carrot, your Carrot partner app receives instructions to lock assets worth the credit amount (principal + 9% of the principal (3 months’ interest)) and an extra 20% of the principal. The additional 20% included, serves as a buffer to mitigate the risks that may arise from fluctuations in stock prices. However, the value of assets locked will reduce with each repayment made.', question: 'How does my Carrot partner app determine the redline amount?' },
      { answer: 'You are receiving this error because your withdrawal on the partner app will encroach into the redline amount. To withdraw successful, select a value lower than what is contained in error message. ', question: 'I am trying to withdraw from my portfolio but I can\'t access the value because I keep getting a redline error message ' },
    ],
  },
  {
    heading: 'Interest Rate',
    qna: [
      { answer: 'The interest rate will be 3% for the first 30 days, and then, the remaining days leading up to the 1st (your next due date) will be prorated. This means that your interest will be 3% plus a prorated amount for the first month of repayment. However, your interest rate will be 3% for the months following your first repayment. Please check your dashboard for the exact amount to be repaid.', question: 'What will my interest rate be on my first ever repayment?' },
      { answer: 'The interest rate for subsequent repayments is 3% per credit serviced.', question: 'After the first repayment what is the interest rate?' },
      { answer: 'Please be aware that interest rates may vary per partner. We advise that prior to securing a line of credit based on your portfolio on a partner app, you must ensure that you confirm the interest rate per month.', question: 'Why do some partners have varying interest rates?' },
      { answer: 'The interest rate will be determined based on the rate assigned to each partner. This means that although users can collectively leverage all available partner portfolios on Carrot, the amount borrowed per portfolio will be handled as a separate credit. As a result, each partner\'s interest rate will apply accordingly.', question: 'If I borrow across my portfolio on several partner apps, how will my interest be calculated?' },
      { answer: 'This depends on the time repayment is to be made. If you want to repay on or before your first ever due date,  your interest will be 3% + the prorated sum. However, if you decide to payoff your debt subsequently, you will be expected to repay 3% interest of the outstanding principal. Your total debt at every time will be displayed on your dashboard to enable you payoff your line of credit.', question: 'What will be the interest rate if I want to repay my debt at once?' },
    ],
  },
  // {
  //   heading: 'Interest Rate (For Trove Users Only)',
  //   qna: [
  //     { answer: 'The interest rate will be 4% for the first 30 days, and then, the remaining days leading up to the 1st (your next due date) will be prorated. This means that your interest will be 4% plus a prorated amount for the first month of repayment. However, your interest rate will be 4% for the months following your first repayment. Please check your dashboard for the exact amount to be repaid.', question: 'What will my interest rate be on my first ever repayment?' },
  //     { answer: 'The interest rate for subsequent repayments is 4% per credit serviced.', question: 'After the first repayment what is the interest rate?' },
  //     { answer: 'Please be aware that interest rates may vary per partner. We advise that prior to securing a line of credit based on your portfolio on a partner app, you must ensure that you confirm the interest rate per month.', question: 'Why are there varying interest rates?' },
  //     { answer: 'The interest rate will be determined based on the rate assigned to each partner. This means that although users can collectively leverage all available partner portfolios on Carrot, the amount borrowed per portfolio will be handled as a separate credit. As a result, each partner\'s interest rate will apply accordingly.', question: 'If I borrow across my portfolio on several partner apps, how will my interest be calculated?' },
  //     { answer: 'This depends on the time repayment is to be made. If you want to repay on or before your first ever due date,  your interest will be 4% + the prorated sum. However, if you decide to payoff your debt subsequently, you will be expected to repay 4% interest of the outstanding principal. Your total debt at every time will be displayed on your dashboard to enable you payoff your line of credit.', question: 'What will be the interest rate if I want to repay my debt at once?' },
  //   ],
  // },
  {
    heading: 'Default on Credit Repayment',
    qna: [
      { answer: 'This means that a direct debit has failed. Kindly check the repayment account details provided and confirm if it is sufficiently funded. Alternatively, you can change the current card details supplied to a sufficiently funded account.', question: 'Why am I receiving notifications about repayment?' },
      { answer: 'If you are required to pay a late payment fee it means that the direct debit on your card failed and you missed the 2 days repayment window after the direct debit failed thrice. To repay, kindly fund your card or add a card that is sufficiently funded.', question: 'Why do I have to pay a late payment fee?' },
      { answer: 'If the credit is disbursed in Naira, 0.75% of the outstanding credit is charged. However, if the credit is disbursed in Dollars, 0.25% of the outstanding credit is charged as the late repayment fee', question: 'How is the late repayment fee calculated?' },
    ],
  },
  {
    heading: 'Liquidation',
    qna: [
      { answer: 'If after 3 months you have defaulted on your repayments your portfolio is automatically liquidated and your credit line is paid off (Balance +3 month’s interest (9% for Bamboo or 12% for Trove) + 3% penal charge). A user’s account can also be liquidated by your Carrot partner application if the value of the stocks in your portfolio declines past a certain amount known as redline.', question: 'Why was my account liquidated?' },
      { answer: 'This consists of the unpaid principal plus unpaid interest for 3 months and a 3% penal charge (1% for every month of default)', question: 'What makes up "liquidation amount"?' },
      { answer: 'For every month that a user defaults on repayment, they incur a 1% penal charge per month on the outstanding amount. This means that after 3 months of default they will have incurred 3%. This penal fee will be added to the outstanding principal and 3 month\'s interest to give you the liquidation amount.', question: 'How is the penal charge calculated?' },
      { answer: 'After a user\'s account is liquidated, they will have to make a strong case for an account reopening with Carrot. The appeal would be subject to approval by the Carrot Management.', question: 'After Liquidation of my account what do I do?' },
      { answer: 'Liquidation of assets is final. We suggest that your funds are used to acquire new assets on your Carrot partner application.', question: 'I was late on my repayment and my account has already been liquidated, but I have my money now, what do I do?' },
      { answer: 'If repayment has been made to our bank account, kindly contact support@carrotcredit.com with the proof of repayment and we will process a refund once our finance team confirms receipt of repayment.', question: 'I have already repaid into your bank account but I realised that I have been liquidated. What do I do?' },
    ],
  },
  {
    heading: 'Exchange Rate',
    qna: [
      { answer: 'The credit is disbursed in dollar and naira and repayment can be done with either currency. If a user repays in Naira, they will be charged the Dollar equivalent. The exchange rate used would be based on the rates provided by our financial partners.', question: 'What currency is used during lending and repayment?' },
      { answer: 'The exchange rate displayed is based on the current rates provided by our financial partners.', question: 'How does Carrot determine the exchange rate?' },
      { answer: 'To view the current exchange rate, log into the App and confirm via the dashboard. Note that, the rate is subject to change based on exchange rate fluctuations and rates received from our Financial Partners.', question: 'How do I confirm the exchange rate used during repayment?' },
    ],
  },
  {
    heading: 'Refunds',
    qna: [
      { answer: 'If you were debited multiple times during repayment your bank should reverse the overpaid value within 24 hours. However, if we receive repayment multiple times a refund will be initiated for the overpaid value. If a refund is not made within 24 hours, kindly send an email to support@carrotcredit.com', question: 'I triggered my repayment and was debited more than once' },
      { answer: 'The refunds timeline is 3 - 5 business days', question: 'What is your refunds timeline?' },
      { answer: 'Your refund will be processed in the currency in which the debit occurred.', question: 'Will my refund come in Naira or Dollars?' },
    ],
  },
  {
    heading: 'Data Security and Privacy',
    qna: [
      { answer: 'The main reason is that we use this information to provide you with details about our products and services. It also helps us improve the product to suit your needs.', question: 'Why do you require my data?' },
      { answer: 'Carrot is in compliance with the NDPB (Nigerian Data Protection Bureau). This indicates that all user data is handled with care in accordance with our privacy notice, which must be accepted before using our service. In other words, your information is secure and will only be used in accordance with the terms you have consented to.', question: 'How do I know that my data is safe on Carrot?' },
      {
        answer:
  <>
    The privacy policy is typically consented to during the sign up process but can be downloaded or read &nbsp;
    <Link style={{ color: '#FE5000', textDecoration: 'none' }} to="/privacy-policy">here</Link>
    .
  </>,
        question: 'How do I access your privacy policy?',
      },
      { answer: 'If after you opt-in, you change your mind, you may withdraw your consent to the continued processing of your personal data, at any time, by contacting us at support@carrotcredit.com', question: 'Can I withdraw my consent?' },
      { answer: 'We may disclose your personal information to third parties where it is in the overall best interest of the user i.e for communication, analytics purposes etc. To get a clear understanding of who we share your data with, check article 9 subsection 9.1 of the privacy policy', question: 'Who do we share your information with?' },
    ],
  },

];
